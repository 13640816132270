<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">租赁商品查询</div>
        <div class="title" v-show="!openState">修改租赁商品</div>
      </div>
      <el-dialog
        width="30%"
        title="查询结果"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-table :data="tableData2" style="width: 100%">
          <el-table-column prop="code_name" label="商品编号" width="180">
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag v-show="scope.row.state == 1">未租售</el-tag>
              <el-tag v-show="scope.row.state == 2">已出售</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-form :model="form" v-if="!openState">
        <el-form-item label="租金" :label-width="formLabelWidth">
          <el-input v-model="form.rent" placeholder="租金"></el-input>
        </el-form-item>
        <el-form-item label="押金" :label-width="formLabelWidth">
          <el-input v-model="form.cash_pledge" placeholder="押金"></el-input>
        </el-form-item>
        <el-form-item label="赔偿金" :label-width="formLabelWidth">
          <el-input v-model="form.damage" placeholder="赔偿金"></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="form" v-if="openState">
        <el-form-item label="商品id" :label-width="formLabelWidth">
          <el-cascader
            :options="options"
            :props="optionProps"
            v-model="demand.mp_id"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>

        <el-form-item label="查询状态" :label-width="formLabelWidth">
          <el-radio v-model="demand.state" :label="1">未租售</el-radio>
          <el-radio v-model="demand.state" :label="2">已租售</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >查 询</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :width="openState ? '30%' : '50%'"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">删除</div>
        <div class="title" v-show="!openState">查看</div>
      </div>
      <span v-show="openState">{{ userdelinfo.mp_name }}</span>
      <el-descriptions v-show="!openState" :column="1" border>
        <el-descriptions-item label="商品名称">{{
          info.name
        }}</el-descriptions-item>
        <el-descriptions-item label="商品详情">{{
          info.detail
        }}</el-descriptions-item>
        <el-descriptions-item label="库存">{{
          info.inventory
        }}</el-descriptions-item>
        <el-descriptions-item label="租金">{{
          info.rent
        }}</el-descriptions-item>
        <el-descriptions-item label="押金">{{
          info.cash_pledge
        }}</el-descriptions-item>
        <el-descriptions-item label="赔偿金">{{
          info.damage
        }}</el-descriptions-item>
        <el-descriptions-item label="分类名称">{{
          info.class_name
        }}</el-descriptions-item>
        <el-descriptions-item label="供应商名称">{{
          info.v_name
        }}</el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag v-show="info.state == 1">上架</el-tag>
          <el-tag v-show="info.state == 2">下架</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
        <div class="menu-box">
            <h2>租赁仓库列表</h2>
            <div class="box-bd">
                <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="catalogue"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="addOpen()">查询</el-button>
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="mp_name" label="商品名称" width="140"></el-table-column>
        <el-table-column prop="rent" label="租金"></el-table-column>
        <el-table-column prop="cash_pledge" label="押金"></el-table-column>
        <el-table-column prop="damage" label="赔偿金"></el-table-column>
        <el-table-column prop="inventory" label="库存数"></el-table-column>
        <el-table-column prop="acc_state" label="类型">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.acc_state == 0">商品</el-tag>
            <el-tag v-show="scope.row.acc_state == 1">配件</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-switch
              @change="changeState(scope.row)"
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
        
              icon="el-icon-search"
              @click="infoOpen(scope.row)"
            >详情</el-button>
            
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        :pager-count="15"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
            </div>
    </div>
      
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getEdit,
  getOption,
  getList2,
  getInfo,
  getState,
} from "@/api/psi/rental.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      info: "",
      spacer: "\u00a0\u00a0\u00a0",
      openState: true,
      innerVisible: false,
      //配置
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      demand: {
        mp_id: [],
        state: 1,
      },
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        rent: "",
        cash_pledge: "",
        damage: "",
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
      tableData2: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true;
    },

    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.page_num = value;
      this.catalogue();
    },
    async changeState(data) {
      let info = await getState({ id: data.id });
      this.judge(info);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      this.catalogue();
    },
    //打开添加
    addOpen() {
      this.openState = true;
      this.dialogFormVisible = true;
    },
    //打开编辑
    editOpen(row) {
      this.openState = false;
      this.dialogFormVisible = true;
      let form = {
        id: row.id,
        rent: row.rent,
        cash_pledge: row.cash_pledge,
        damage: row.damage,
      };
      this.form = form;
      console.log(this.form);
    },
    // 详情打开
    async infoOpen(row) {
      this.openState = false;
      let info = await getInfo({ id: row.id });
      this.info = info.data.data;
      this.dialogVisible = true;
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form);
      this.judge(info);
    },
    //删除打开
    delOpen(row, form) {
      this.openState = true;
      this.dialogVisible = true;
      this.userdelinfo = row;
      console.log(this.userdelinfo);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      let form = JSON.stringify(this.demand);
      form = JSON.parse(form);
      if (!form.mp_id.length) {
        return;
      }
      form.mp_id = form.mp_id[2];

      let info = await getList2(form);
      this.tableData2 = info.data.data;
      this.innerVisible = true;
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.catalogue();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        rent: "",
        cash_pledge: "",
        damage: "",
      };
      console.log("close");
    },
    //目录接口
    async catalogue() {
      let info = await getList(this.getInfo);
      let options = await getOption();
      this.options = options.data.data;
      this.tableData = info.data.data;
      this.exp.count = info.data.exp.count;
      this.exp.num = info.data.exp.num;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>