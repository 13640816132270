import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/rental/listRental', data)
}
//修改
export function getEdit(data) {
    return post('psi/rental/editRental', data)
}
//查询
export function getList2(data) {
    return post('psi/rental/inventory', data)
}
// 详情
export function getInfo(data) {
    return post('psi/rental/infoRental', data)
}
//商品id
export function getOption(data) {
    return post('psi/commodity/xiala', data)
}
//状态
export function getState(data) {
    return post('psi/rental/upRental', data)
}


